// auth.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface IAuth {
  token?: string;
  user?: IAuthUser
}

interface IAuthUser {
  sub: string,
  name: string,
  family_name: string,
  email: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private dataAuth?: IAuth = undefined;
  private isLoggedInSubject: BehaviorSubject<boolean>;
  isLoggedIn$: Observable<boolean>;

  constructor() {
    this.isLoggedInSubject = new BehaviorSubject<boolean>(false);
    this.isLoggedIn$ = this.isLoggedInSubject.asObservable();
  }


  setIsLoggedIn(loggedIn: boolean): void {
    this.isLoggedInSubject.next(loggedIn);
  }

  setAuth(data: IAuth): void {
    this.dataAuth = data;

    if(data.token) {
      sessionStorage.setItem('user', JSON.stringify(data.user));
      sessionStorage.setItem('authToken', data.token);
    }
  }

  logout(): void {
    this.dataAuth = undefined;
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('authToken');
    this.setIsLoggedIn(false);
  }

  getAuthToken(): string {
    if(this.dataAuth?.token){
      return this.dataAuth.token;
    }
    const token: string | null = sessionStorage.getItem('authToken');
    const user: string | null = sessionStorage.getItem('user');

    if(token && user) {
      const parseUser: IAuthUser = JSON.parse(user);
      this.setAuth({
        token,
        user: parseUser
      });

      return token;
    }

    return '';
  }

  getAuthUser(): IAuthUser | null {
    if(this.dataAuth?.user){
      return this.dataAuth.user;
    }
    const token: string | null = sessionStorage.getItem('authToken');
    const user: string | null = sessionStorage.getItem('user');

    if(token && user) {
      const parseUser: IAuthUser = JSON.parse(user);
      this.setAuth({
        token,
        user: parseUser
      });

      return parseUser;
    }

    return null;
  }
}
